  .Table__itemCount {
    /* margin-top: 10px; */
    font-size: 14px;
  }
  
  .Table__pagination {
    display: flex;
    justify-content: flex-end;
    padding: 20px 10px;
  }
  
  .Table__pageButton {
    font-size: 15px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
  }
  
  .Table__pageButton--active {
    color: #45b3e3;
    font-weight: bold;
  }

  .ReactTable .rt-thead .rt-th.-sort-asc,
  .ReactTable .rt-thead .rt-td.-sort-asc {
      box-shadow: inset 0 0px 0 0 rgba(0, 0, 0, 0.6)
  }

  .ReactTable .rt-thead .rt-th.-sort-desc,
  .ReactTable .rt-thead .rt-td.-sort-desc {
      box-shadow: inset 0 0px 0 0 rgba(0, 0, 0, 0.6)
  }

  div.-sort-desc::after {
      content: " \25BC";
      float: right;
  }

  div.-sort-asc::after {
      content: " \25B2";
      float: right;
  }

  .MuiDrawer-root .MuiListItem-root.MuiListItem-gutters:nth-child(3), .MuiDrawer-root .MuiListItem-root.MuiListItem-gutters:nth-child(7){
    border-bottom: 3px solid #2e85d2;
  }


  
  